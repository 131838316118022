
<template>
    <div class="purchaseRawMaterialList">
        <el-form
            ref="formData"
            :rules="rules"
            :model="formData"
        >
            <table
                border="0"
                align="center"
                cellpadding="0"
                cellspacing="0"
                class="ng-scope"
            >
                <tr>
                    <th colspan="4" class="trHeader">
                        原材料采购表
                    </th>
                </tr>
                <tr>
                    <th>合同编号：</th>
                    <td>
                        {{ formData.contract.contract_no }}
                    </td>
                    <th>合同名称：</th>
                    <td>
                        {{ formData.contract.contract_name }}
                    </td>
                </tr>
                <tr>
                    <th>供货商</th>
                    <td>{{ formData.contract.supplier_name }}</td>
                </tr>
                <tr>
                    <th colspan="4" class="trHeader">
                        <div class="rowBox">
                            <span>物料信息表</span>
                            <div class="btn" @click="addRow">
                                添加
                            </div>
                        </div>
                    </th>
                </tr>
                <tr
                    v-for="(item, index) in formData.materialRow"
                    :key="index"
                >
                    <td
                        class="td-box"
                        colspan="4"
                        @contextmenu="rightClick(index,$event)"
                    >
                        <table>
                            <tr>
                                <th><span class="NotAllowNullCss">*</span>原材料名称</th>
                                <td class="pl0" @click="selectType(index, item)">
                                    <el-form-item
                                        :prop="'materialRow.' + index + '.material.type_name'"
                                        :rules="rules.type_name"
                                    >
                                        <el-input
                                            v-model="item.material.type_name"
                                            placeholder="请选择原材料"
                                            readonly
                                        >
                                        </el-input>
                                    </el-form-item>
                                </td>
                                <th>原材料子类</th>
                                <td>{{ item.material.child_type_name }}</td>
                            </tr>
                            <tr>
                                <th>规格型号</th>
                                <td>{{ item.material.spec_name }}</td>
                                <th><span class="NotAllowNullCss">*</span>货源地</th>
                                <td class="pl0" @click="selectSource(index, item)">
                                    <el-form-item
                                        :prop="'materialRow.' + index + '.material.source_name'"
                                        :rules="rules.source_name"
                                    >
                                        <el-input
                                            v-model="item.material.source_name"
                                            readonly
                                            placeholder="请选择货源地"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="NotAllowNullCss">*</span>供应站点</th>
                                <td class="pl0" colspan="3">
                                    <el-form-item
                                        :prop="'materialRow.' + index + '.material.station_names'"
                                        :rules="rules.station_names"
                                    >
                                        <el-input
                                            v-model="item.material.station_names"
                                            @focus="selectStation(index, item)"
                                            readonly
                                            placeholder="请选择供应站点"
                                        >
                                        </el-input>
                                    </el-form-item>
                                </td>
                            </tr>
                            <tr>
                                <th><span class="NotAllowNullCss">*</span>税率（%）</th>
                                <td class="pl0">
                                    <el-form-item
                                        :prop="'materialRow.' + index + '.material.tax_rate'"
                                        :rules="rules.tax_rate"
                                    >
                                        <el-input
                                            placeholder="请输入"
                                            type="number"
                                            v-model="item.material.tax_rate"
                                            :readonly="isRead"
                                        ></el-input>
                                    </el-form-item>
                                </td>
                                <th><span class="NotAllowNullCss">*</span>是否存在超吨</th>
                                <td class="pl0">
                                    <el-form-item
                                        :prop="'materialRow.' + index + '.material.overweight_value'"
                                        :rules="item.material.is_exist_overweight?rules.overweight_value:[]"
                                    >
                                        <div class="overWeightInput">
                                            <el-radio v-model="item.material.is_exist_overweight" :label="true">
                                                是
                                            </el-radio>
                                            <el-radio v-model="item.material.is_exist_overweight" :label="false">
                                                否
                                            </el-radio>
                                            <template v-if="item.material.is_exist_overweight">
                                                超吨值：
                                                <el-input
                                                    placeholder=""
                                                    type="number"
                                                    v-model="item.material.overweight_value"
                                                    :readonly="isRead"
                                                ></el-input>
                                                吨
                                            </template>
                                        </div>
                                    </el-form-item>
                                </td>
                            </tr>
                            <tr v-if="item.materialGrades.length==0">
                                <th><span class="NotAllowNullCss">*</span>含税价(不超吨)(元)</th>
                                <td class="pl0">
                                    <el-form-item
                                        :prop="'materialRow.' + index + '.material.unit_price'"
                                        :rules="rules.unit_price"
                                    >
                                        <el-input
                                            placeholder="请输入"
                                            type="number"
                                            v-model="item.material.unit_price"
                                            :readonly="isRead"
                                        ></el-input>
                                    </el-form-item>
                                </td>
                                <th><span class="NotAllowNullCss" v-show="item.material.is_exist_overweight">*</span>含税价(超吨)(元)</th>
                                <td class="pl0">
                                    <el-form-item
                                        :prop="'materialRow.' + index + '.material.overweight_price'"
                                        :rules="item.material.is_exist_overweight?rules.overweight_price:[]"
                                    >
                                        <el-input
                                            placeholder="请输入"
                                            type="number"
                                            v-model="item.material.overweight_price"
                                            :readonly="isRead"
                                            :disabled="!item.material.is_exist_overweight"
                                        ></el-input>
                                    </el-form-item>
                                </td>
                            </tr>
                            <tr v-if="item.materialGrades.length>0">
                                <td colspan="4" class="grade-box">
                                    <table>
                                        <tr>
                                            <th>原材料品级</th>
                                            <th>
                                                <span class="NotAllowNullCss">*</span>
                                                <span>含税价（不超吨）</span>
                                            </th>
                                            <th>
                                                <span
                                                    class="NotAllowNullCss"
                                                    v-show="item.material.is_exist_overweight
                                                        &&item.material.transport_name==''"
                                                >
                                                    *
                                                </span>
                                                含税价（超吨）
                                            </th>
                                        </tr>
                                        <tr v-for="(grade,gradeIndex) in item.materialGrades" :key="gradeIndex">
                                            <td>{{ grade.grade_name }}</td>
                                            <td>
                                                <el-form-item
                                                    :prop="'materialRow.' + index + '.materialGrades.' + gradeIndex + '.unit_price'"
                                                    :rules="rules.unit_price"
                                                >
                                                    <el-input
                                                        placeholder="请输入"
                                                        type="number"
                                                        v-model="grade.unit_price"
                                                        :readonly="isRead"
                                                    ></el-input>
                                                </el-form-item>
                                            </td>
                                            <td>
                                                <el-form-item
                                                    :prop="'materialRow.' + index + '.materialGrades.' + gradeIndex + '.overweight_price'"
                                                    :rules="!item.material.is_exist_overweight||item.material.transport_name
                                                        ?[]:rules.overweight_price"
                                                >
                                                    <el-input
                                                        class="disabledInput2"
                                                        placeholder="请输入"
                                                        type="number"
                                                        v-model="grade.overweight_price"
                                                        :readonly="isRead"
                                                        :disabled="!item.material.is_exist_overweight||item.material.transport_name!=''"
                                                    ></el-input>
                                                </el-form-item>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                            <template v-if="formData.contract.transport_contractor==0">
                                <tr>
                                    <th>
                                        <span class="NotAllowNullCss" v-show="item.material.transport_name">*</span>运输含税单价(不超吨)(元)
                                    </th>
                                    <td class="pl0">
                                        <div class="cell">
                                            <el-form-item
                                                :prop="'materialRow.' + index + '.material.transport_price'"
                                                :rules="item.material.transport_name?rules.transport_price:[]"
                                            >
                                                <el-input
                                                    v-model="item.material.transport_price"
                                                    type="number"
                                                    placeholder="请输入运输含税单价(元)"
                                                    :readonly="isRead"
                                                ></el-input>
                                            </el-form-item>
                                        </div>
                                    </td>
                                    <th>
                                        <span class="NotAllowNullCss" v-show="item.material.transport_name&&item.material.is_exist_overweight">
                                            *</span>
                                        运输含税单价(超吨)(元)
                                    </th>
                                    <td class="pl0">
                                        <el-form-item
                                            :prop="'materialRow.' + index + '.material.overweight_transport_price'"
                                            :rules="item.material.transport_name&&item.material.is_exist_overweight
                                                ?rules.overweight_transport_price:[]"
                                        >
                                            <el-input
                                                class="disabledInput2"
                                                v-model="item.material.overweight_transport_price"
                                                type="number"
                                                placeholder="请输入运输含税单价(元)"
                                                :readonly="isRead"
                                                :disabled="!item.material.is_exist_overweight"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <th>税率（%）：</th>
                                    <td colspan="3" class="pl0">
                                        <el-input
                                            v-model="item.material.transport_rate"
                                            type="number"
                                            placeholder="请输入税率"
                                            :readonly="isRead"
                                        ></el-input>
                                    </td>
                                </tr>
                            </template>
                        </table>
                    </td>
                </tr>
            </table>
        </el-form>
        <div
            id="contextmenu"
            v-show="menuVisible"
            class="menu"
        >
            <div class="contextmenu__item" @click="addRow">
                <i class="el-icon-edit-outline"></i>
                添加新行
            </div>
            <div class="contextmenu__item" @click="deleteRow">
                <i class="el-icon-delete"></i>
                删除此行
            </div>
        </div>
    </div>
</template>

<script>
const materialStruts = {
    material: {
        id: '',
        type_name: '',
        type_code: '',
        type_dic_code: '',
        child_type_name: '',
        child_type_code: '',
        child_type_dic_code: '',
        spec_code: '',
        spec_name: '',
        spec_dic_code: '',
        source_id: '',
        source_name: '',
        catalog_name: '',
        station_ids: '',
        station_names: '',
        measure_unit: '',
        insurance_supply_type: null,
        insurance_supply: null,
        unit_price: null,
        tax_rate: null,
        transport_id: '',
        transport_name: '',
        transport_price: null,
        transport_rate: null,
        remarks: '',
        sort_num: null,
        is_exist_overweight: true,
        overweight_price: null,
        overweight_value: null,
        overweight_transport_price: null,
    },
    materialGrades: [
        // {
        //     id: '',
        //     ciid: '',
        //     cmid: '',
        //     grade_code: '',
        //     grade_name: '',
        //     unit_price: 0,
        //     overweight_price: 0,
        //     sort_num: 0,
        // },
    ],
};
export default {
    name: 'purchase-raw-material-list',
    components: {},
    data() {
        return {
            business_instance_id: '', // 流程实例id
            menuVisible: false,
            formData: {
                contract: {
                    contract_no: '',
                    contract_name: '',
                    supplier_name: '',
                }, // 合同数据
                // 原材料列表
                materialRow: [this.simpleCopy(materialStruts)],
            },
            curRowIndex: -1, // 当前操作的行
            rules: {
                type_name: [{ type: '', required: true, message: '原材料不能为空', trigger: 'change' }],
                source_name: [{ required: true, message: '货源地不能为空', trigger: 'change' }],
                station_names: [{ required: true, message: '供应站点不能为空', trigger: 'change' }],
                tax_rate: [{ required: true, message: '税率不能为空', trigger: 'change' }],
                overweight_value: [{ required: true, message: '超吨值不能为空', trigger: 'change' }],
                unit_price: [{ required: true, message: '含税单价(不超吨)不能为空', trigger: 'change' }],
                overweight_price: [{ required: true, message: '含税单价(超吨)不能为空', trigger: 'change' }],
                transport_price: [{ required: true, message: '运输含税单价(不超吨)不能为空', trigger: 'change' }],
                overweight_transport_price: [{ required: true, message: '运输含税单价(超吨)不能为空', trigger: 'change' }],
            },
            isRead: false, // 是否只读
        };
    },
    props: {
        vueFormParams: {
            type: [Object],
        },
    },
    created() {
        this.business_instance_id = this.vueFormParams.businessInstanceId;
        this.isRead = this.vueFormParams.IsRead;
        if (this.business_instance_id) {
            this.getData();
            this.getMaterials();
        }
    },
    methods: {
        simpleCopy(data) {
            return JSON.parse(JSON.stringify(data));
        },
        // 添加新行
        addRow() {
            const data = this.simpleCopy(materialStruts);
            data.ciid = this.formData.contract.id;
            this.formData.materialRow.push(data);
        },
        // 删除此行
        deleteRow() {
            this.formData.materialRow.splice(this.curRowIndex, 1);
        },
        // 初始化表单数据
        getData() {
            this.$axios
                .get(`/interfaceApi/procurement/contract/ContractInfo/${this.business_instance_id}`)
                .then(res => {
                    if (res) {
                        this.formData.contract = res;
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        // 获取物料信息表
        getMaterials() {
            this.$axios
                .get(`/interfaceApi/procurement/contract/material/businessinstanceid/${this.business_instance_id}`)
                .then(res => {
                    if ((res || []).length > 0) {
                        this.formData.materialRow = res;
                    }
                    this.watchEditingStatus();
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },

        watchEditingStatus() {
            this.$watch('formData.materialRow', {
                handler: () => {
                    window.formEditingStatus = true;
                },
                deep: true,
                immediate: false,
            });
        },
        // 保存
        save(successCallback, errorCallback) {
            this.$refs.formData.validate((valid, data) => {
                if (valid) {
                    this.$axios
                        .post(`/interfaceApi/procurement/contract/material/save/${this.business_instance_id}`, this.formData.materialRow)
                        .then(res => {
                            if (res) {
                                window.formEditingStatus = false;
                                successCallback();
                            }
                        })
                        .catch(error => {
                            this.$message.error(error.ErrorCode.Message);
                        });
                } else {
                    const msgs = new Set();
                    for (const i in data) {
                        msgs.add(data[i][0].message);
                    }
                    this.$message.warning(Array.from(msgs).join());
                    errorCallback();
                }
            });
        },
        // 选择原材料
        selectType(index) {
            if (this.isRead) {
                return;
            }
            this.curRowIndex = index;
            this.$toast({
                title: true,
                text: '选择原材料',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_ht_xzycl',
                        QueryString: `supplier_id=${this.formData.contract.supplier_id}`,
                        ViewCode: 'one',
                    },
                    selectTypeCallback: this.confirmSelectType,
                },
            });
        },
        // 选择原材料回调
        confirmSelectType(data) {
            this.formData.materialRow[this.curRowIndex].material.type_name = data.type_name;
            this.formData.materialRow[this.curRowIndex].material.type_code = data.type_code;
            this.formData.materialRow[this.curRowIndex].material.type_dic_code = data.type_dic_code;
            this.formData.materialRow[this.curRowIndex].material.child_type_name = data.child_type_name;
            this.formData.materialRow[this.curRowIndex].material.child_type_code = data.child_type_code;
            this.formData.materialRow[this.curRowIndex].material.child_type_dic_code = data.child_type_dic_code;
            this.formData.materialRow[this.curRowIndex].material.spec_code = data.spec_code;
            this.formData.materialRow[this.curRowIndex].material.spec_dic_code = data.spec_dic_code;
            this.formData.materialRow[this.curRowIndex].material.spec_name = data.spec_name;
            this.getGradeList();
        },
        // 选择货源地
        selectSource(index) {
            if (this.isRead) {
                return;
            }
            this.curRowIndex = index;
            if (this.formData.materialRow[index].material.type_code === '') {
                this.$message({
                    message: '请先选择原材料！',
                    type: 'warning',
                });
            } else {
                this.$toast({
                    title: true,
                    text: '选择货源地',
                    type: 'eject',
                    width: '70%',
                    t_url: 'tableList/index',
                    extr: {
                        code: {
                            TableCode: 'cg_hydxz_ycldl',
                            QueryString: `type_dic_code=${this.formData.materialRow[index].material.type_dic_code}`,
                            ViewCode: 'one',
                        },
                        selectTypeCallback: this.confirmSelectSource,
                    },
                });
            }
        },
        // 选择货源地回调
        confirmSelectSource(data) {
            this.formData.materialRow[this.curRowIndex].material.source_name = data.name;
            this.formData.materialRow[this.curRowIndex].material.source_id = data.id;
        },
        // 选择场站
        selectStation(index) {
            if (this.isRead) {
                return;
            }
            this.curRowIndex = index;
            this.$toast({
                title: true,
                text: '选择供应站点',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_htxxb_xzcz',
                    },
                    selectTypeCallback: this.confirmSelectStation,
                },
            });
        },
        // 选择场站回调
        confirmSelectStation(data) {
            const list = data || [];
            const station_names = [];
            const station_ids = [];
            list.forEach(item => {
                station_names.push(item.dept_name);
                station_ids.push(item.dept_id);
            });
            this.formData.materialRow[this.curRowIndex].material.station_names = station_names.join();
            this.formData.materialRow[this.curRowIndex].material.station_ids = station_ids.join();
        },
        // 选择运输单位
        selectTransport(index) {
            if (this.isRead) {
                return;
            }
            this.curRowIndex = index;
            this.$toast({
                title: true,
                text: '选择运输单位',
                type: 'eject',
                width: '70%',
                t_url: 'tableList/index',
                extr: {
                    code: {
                        TableCode: 'cg_htxxb_xzysdw',
                        QueryString: `businessInstanceId=${this.business_instance_id}`,
                    },
                    selectTypeCallback: this.confirmSelectTransport,
                },
            });
        },
        // 选择运输单位
        confirmSelectTransport(data) {
            this.formData.materialRow[this.curRowIndex].material.transport_id = data.transport_id;
            this.formData.materialRow[this.curRowIndex].material.transport_name = data.transport_name;
        },
        // 获取品级列表数据
        getGradeList() {
            const curRowMaterial = this.formData.materialRow[this.curRowIndex].material;
            const params = {
                type_dic_code: curRowMaterial.type_dic_code,
                child_type_code: curRowMaterial.child_type_code,
                child_type_dic_code: curRowMaterial.child_type_dic_code,
                spec_code: curRowMaterial.spec_code,
                spec_dic_code: curRowMaterial.spec_dic_code,
            };
            this.$axios
                .post('/interfaceApi/procurement/quality/config/materialgrades', params)
                .then(res => {
                    res = res || [];
                    const materialGrades = [];
                    res.forEach((item, index) => {
                        materialGrades.push({
                            ciid: this.formData.contract.id,
                            grade_code: item.grade_code,
                            grade_name: item.grade_name,
                            unit_price: null,
                            overweight_price: null,
                            sort_num: index,
                        });
                    });
                    this.formData.materialRow[this.curRowIndex].materialGrades = materialGrades;
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 鼠标右键
        rightClick(index, event) {
            event.preventDefault(); // 关闭浏览器右键默认事件
            this.menuVisible = false; // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
            this.menuVisible = true; // 显示模态窗口，跳出自定义菜单栏
            const menu = document.querySelector('.menu');
            this.styleMenu(menu);
            this.curRowIndex = index;
        },
        foo() {
            // 取消鼠标监听事件 菜单栏
            this.menuVisible = false;
            document.removeEventListener('click', this.foo); // 关掉监听，
        },
        styleMenu(menu) {
            if (event.clientX > 1800) {
                menu.style.left = event.clientX - 100 + 'px';
            } else {
                menu.style.left = event.clientX + 1 + 'px';
            }
            document.addEventListener('click', this.foo); // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
            if (event.clientY > 700) {
                menu.style.top = event.clientY - 30 + 'px';
            } else {
                menu.style.top = event.clientY - 10 + 'px';
            }
        },
    },
};
</script>

<style lang="stylus" scoped>
    .purchaseRawMaterialList{
        width 100%;
        height 100%;
        position relative;
        overflow-x hidden;
        overflow-y auto;
        .el-form-item__content{
            overflow hidden
        }
        table{
            width 100%;
            border-spacing: 0;
            border-collapse: collapse;
            th,td{
                border: 1px solid #D7D7D7;
                min-width: 0.6rem;
                text-align left;
                overflow-y hidden
            }
            th{
                background-color: #F6F8FC;
                color: #002B94;
                font-weight: normal;
                border-collapse: collapse;
                line-height: 0.4rem;
                font-size: 0.16rem;
                border: 1px solid #D7D7D7;
                border-right: none;
                padding-left: 0.2rem;
                width: 20%;
                &.trHeader{
                    height: 0.42rem;
                    text-align: center;
                    background-color: #EDF0F5;
                    font-size: 0.16rem;
                    color: #002B94;
                    position: relative;
                }
            }
            td{
                width 30%;
                background-color: #fff;
                font-family: "microsoft yahei";
                color: #060606;
                font-weight: normal;
                border: 1px solid #D7D7D7;
                font-size: 0.16rem;
                position: relative;
                padding-left: 0.2rem;
                overflow hidden;
                .el-form-item,/deep/ .el-form-item{
                    margin-bottom 0;
                }
                .el-input{
                    display flex
                }
                input,/deep/ input,.el-select,/deep/ .el-select{
                    width 100%;
                    height: 0.4rem;
                    font-size: 0.16rem;
                    color: #333;
                    border-color transparent;
                    border-radius 0;
                    outline: none;
                    font-family: "microsoft yahei";
                    resize: none;
                    padding-left: 0.2rem;
                    vertical-align: top;
                    cursor pointer
                }
                .el-select,/deep/ .el-select{
                    border 0;
                    padding-left 0;
                }
                &.pl0{
                    padding-left 0;
                }
            }
            .grade-box{
                padding: 0;
                th,td{
                    width 16.5%;
                    white-space nowrap
                    text-align center
                    padding 0;
                }
                input,/deep/ input {
                    text-align center;
                }
            }
            .td-box{
                padding 0.1rem 0;
            }
            .rowBox{
                width 100%;
                display flex
                justify-content space-between;
                align-items center;
            }
            .btn{
                margin 0 0.06rem;
                width: .46rem;
                height: .28rem;
                background: rgba(21,119,210,1);
                border-radius: 0.02rem;
                border: none;
                color: #fff;
                text-align: center;
                line-height: .28rem;
                font-size: .14rem;
                cursor: pointer;
            }
            .NotAllowNullCss{
                color #D6000F;
            }
        }
        .contextmenu__item {
            display: block;
            line-height: 0.34rem;
            text-align: center;
            i{
                margin-right 0.05rem;
                color #2980b9;
            }
        }
        .contextmenu__item:not(:last-child) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        }
        .menu {
            position: fixed;
            background-color: #fff;
            width: 1rem;
            /*height: 106px;*/
            font-size: 0.12rem;
            color: #444040;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            border-radius: 0.03rem;
            border: 1px solid rgba(0, 0, 0, 0.15);
            box-shadow: 0 0.06rem 0.12rem rgba(0, 0, 0, 0.175);
            white-space: nowrap;
            z-index: 1000;
        }
        .contextmenu__item:hover {
            cursor: pointer;
            background: #66b1ff;
            border-color: #66b1ff;
            color: #fff;
        }
        .overWeightInput{
            padding-left 0.2rem;
            >>> input{
                height 0.28rem;
            }
            .el-radio{
                margin-right 0.14rem;
            }
            .el-input{
                display inline-block
                width 0.7rem;
                >>> .el-input__inner{
                    height 0.33rem;
                    border-color transparent
                    border-bottom 1px solid #000
                }
            }
        }
        >>> input::-webkit-outer-spin-button, >>> input::-webkit-inner-spin-button {
            -webkit-appearance: none!important;
        }
        >>> input[type="number"]{
            -moz-appearance: textfield!important;
        }
        .disabledInput2{
            >>> input[readonly="readonly"]{
                background #F5F7FA
            }
        }
    }
</style>
